import { styled } from "styled-components";

const Wrapper = styled.div`
    background : #000;
    padding : 50px 0;
    @media only screen and (max-device-width: 480px) {
        .left{
            display : none;
        }
    }
    .inner{
        display : flex;
        align-items : center;
        .left {
            h2{
                color : #fff;
                padding : 10px 0;
            }
            a{
                display : inline-block;
                padding : 10px 0px;
                color : #fff;
                width : 100%;
                text-decoration : none;
                &:hover{
                    text-decoration : underline;
                }
            }
        }
        .right {
            text-align : right;
            flex : 1;
        }
    }
    p{
        color : #fff;
        display : flex;
        justify-content : end;
        align-items : center;
        gap : 10px;
        margin : 10px auto;
    }
    img{
        width : 80px;
        margin : 20px 0;
    }
    
`

export default Wrapper