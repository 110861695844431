import { useState } from "react"
import Wrapper from "./style"

export const Calculator = () => {

    const [premium, setPremium] = useState(10000)
    const [interest, setInterest] = useState(5)
    const [year, setYear] = useState(4)

    return (
        <Wrapper>
            <div className="inner">
                <h1>EMI Calculator</h1>
                
                <div>
                Premium : (₹) {premium} <br /><input type="range" min="0" max="50000000" step="10000" list="markers" value={premium} onChange={e => setPremium(+e.target.value)} />
                {/* <datalist id="markers">
                <option value="0"></option>
                <option value="5000000" label="0.5L"></option>
                <option value="10000000" label="1L"></option>
                <option value="15000000" label="1.5L"></option>
                <option value="20000000" label="2L"></option>
                <option value="25000000" label="2.5L"></option>
                <option value="30000000" label="3L"></option>
                <option value="35000000" label="3.5L"></option>
                <option value="40000000" label="4L"></option>
                <option value="45000000" label="4.5L"></option>
                <option value="50000000" label="5L"></option>
                </datalist> */}
                </div>
                <div>
                Interest : {interest}% <br /><input type="range" min="4" max="24" step="0.01" value={interest} onChange={e => setInterest(+e.target.value)} />
                </div>
                <div>
                Tenure (In Years) : {year} <br /><input type="range" min="1" max="30" step="1" value={year} onChange={e => setYear(+e.target.value)} />
                </div>

                <br />

                <h2>EMI : (₹) {((premium * interest/1200 * Math.pow(1 + interest/1200, year * 12)) / (Math.pow(1 + interest/1200, year * 12) - 1)).toFixed(2)}</h2><br />
                <h2>Total Interest : (₹) {((((premium * interest/1200 * Math.pow(1 + interest/1200, year * 12)) / (Math.pow(1 + interest/1200, year * 12) - 1)).toFixed(2)) * year * 12 - premium).toFixed(2)}</h2>
            </div>
        </Wrapper>
    )
}