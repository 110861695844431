import Wrapper from "./style";
import image1 from './growth.png'
import image2 from "./startup.png"
import image3 from "./up-arrow.png"
import image4 from "./crowdfunding.png"
import image5 from "./deal.png"
import image6 from "./skyscraper.png"
import image7 from "./insurance.png"
import image8 from "./piggy-bank.png"
import image9 from "./taxes.png"
import { useState } from "react";
import { Contact } from "../contact";

export const Services = () => {

    const [selected, setSelected] = useState(null)
    const [explore, setExplore] = useState(false)
    
    const items = [{
        id : `services-1`,
        name : 'Project Loans',
        src : image1,
        description : `<b>Project Finance</b> – Focuses on opportunities in infrastructure, core and manufacturing sectors. Offers comprehensive solutions for mid-sized projects and collaborate with other financing institutions for larger projects and consortium finance. 
        <br /><br /><b>Working Capital</b> – We offer working capital solutions to suit an array of short-term business requirements of Medium, small and micro-organisations.
        <br /><br /><b>Term Loans</b> – Our corporate term loans can help companies expand operations, acquire equipment, manpower and upgrade existing processes.
        `
    },{
        id : `services-2`,
        name : 'Subsidies',
        src : image2,
        description : `
        A subsidy or government incentive is a form of financial aid or support extended to businesses, specially MSME’s with the aim of providing employment opportunities, contribute to manufacturing and exports, and support innovation and entrepreneurship.
        <br /><br />We at Smart Move Financiers aim at providing hassle free subsidy approval and claim process which is otherwise a mammoth task to go through.
        <br /><br />Govt. is granting Capital Subsidy and Interest subsidy under various schemes. Reference material under below link - https://invest.rajasthan.gov.in/publications
        
        `
    },{
        id : `services-3`,
        name : 'Retail Loans',
        src : image3,
        description : `
        Retail Loans include: -<br /><br />
        -	Personal Loan<br />
        -	Business Loan<br />
        -	Home Loan<br />
        -	Loan against Property<br />
        -	Loan against Shares/Securities<br />
        -	New Car/Used Car Loan<br /><br />
        Smart Move Financiers brings you the best deal in retail loans using our extensive network of partner banks and NBFC’s with minimum turn around time.
        
        `
    },{
        id : `services-4`,
        name : 'Private Equity',
        src : image4,
        description : `
        We offer customised and innovative solutions for long-term working capital requirements, bridge loans, acquisition financing and equity investment requirements.
        <br /><br />We got association with vintage investors who have expertise in revival of struggling units by way of putting in equity and experienced promoters in same line of business.
        
        `
    },{
        id : `services-5`,
        name : 'NPA Management',
        title : '(Non Performing Assets)',
        src : image5,
        description : '-	We serve as a catalyst between banks and NPA clients in dispute resolution mechanism and one time settlement with an ice breaker and attractive deal in favour of client in reasonable time. We feel proud in saying that we executed high ticket deals successfully and have a good tie-up with lawyers'
    },{
        id : `services-6`,
        name : 'Builder Funding and APF',
        title : '(Approved Project Funding)',
        src : image6,
        description : '-	We are regularly doing home loans for various high-rise projects and having APF docs for reference. We provide best solution for seasoned builder as well as new entrepreneur enters in Building Construction line.'
    },{
        id : `services-7`,
        name : 'Insurance Business',
        src : image7,
        description : '-	We are primarily focused on Term Insurance, Life Insurance and Health Insurance for our existing and new clients. We have corporate codes/channel from Insurance Companies.'
    },{
        id : `services-8`,
        name : 'Investments – Wealth services and Retirement Planning',
        src : image8,
        description : `
        We provide the investment solutions and PMS services with optimal returns. Deals in Mutual funds, Investment and trading in shares and securities.
        <br /><br />Loan against Shares is an important tool to get a low-cost funding on existing portfolio.
        

        `
    },{
        id : `services-9`,
        name : 'Business Advisory and Tax Planning',
        src : image9,
        description : `
        We believe in long term association with the clients hence once associated we provide continued advisory on improving its balance sheet and ratios to make the company eligible for low-cost funds.
        <br /><br />Tax planning through are associated chartered accountants. This is done through tax management and investments.
        
        `
    }]

    const hide = e => {
        setSelected(null)
        setExplore(null)
    }

    return (
        <Wrapper name="services">

            {
                selected
                ? (
                    <div className="popupWrapper">
                        <div className="servicePopup">
                            <h2>{selected.name}</h2><br />
                            {
                                explore
                                ? <Contact subject={selected.name} />
                                : <><p style={{textAlign : 'justify', fontSize : '20px'}} contentEditable='true' dangerouslySetInnerHTML={{__html : selected.description}}></p><br /></>
                            }
                            
                            {
                                explore || <input type="button" value={"Explore More"} onClick={e => setExplore(s => !s)} />
                            }

                            <input type="button" value={"Close"} onClick={hide} />
                            
                            
                        </div>
                    </div>
                )
                : null
            }

            <div className="inner">
                <h1>Our Services</h1>

                <div className="cards">
                    {
                        items.map(item => (
                            <div title={item.name + " " + (item.title || '')} className="card" key={item.id} onClick={e => setSelected(item)}>
                                <img src={item.src} alt={item.name} />
                                <h2>{item.name}</h2>
                            </div>
                        ))
                    }
                </div>

            </div>
        </Wrapper>
    )
}
