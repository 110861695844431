import { useNavigate } from "react-router"
import Wrapper from "./style"

export const Cover = () => {

    const navigate = useNavigate()

    return (
        <Wrapper>
            <div className="inner">
                <h1>Grow with us</h1>
                <p>Smart Move Financiers. Your Trust, Our Expertise.</p>
                <input type="button" value="Get Started" onClick={e => navigate('/contact')} />
            </div>
        </Wrapper>
    )
}