import { styled } from "styled-components";

const Wrapper = styled.div`

    .inner{
        padding : 50px 0;
        h1{
            margin : 30px 0;
        }
        h2{
            font-size : 20px;
            margin-bottom : 10px;
        }
        .leader{
            margin : 20px auto;
        }
        p{
            font-size : 20px;
        }
    }
`

export default Wrapper