import Wrapper from "./style"
import flag from "./Flag_of_India.svg.png"
import { Facebook, Instagram, LinkedIn, YouTube } from "@material-ui/icons"

export const Footer = () => {
    return (
        <Wrapper>
            <div className="inner">
                <div className="left">
                    <h2>Useful Links</h2>
                    <a href="https://sachet.rbi.org.in">RBI Sachet Portal</a>
                    <a href="https://rbi.org.in/Scripts/Complaints.aspx">RBI Ombudsman</a>
                    <a href="https://www.cibil.com">Cibil</a>
                    <a href="https://www.nseindia.com">NSE India</a>
                    <a href="https://www.bseindia.com">BSE India</a>
                    <a href="https://www.moneycontrol.com">Money Control</a>
                </div>

                <div className="right">
                    <p>Proudly Made in India</p>
                    <img src={flag} alt="India" />
                    <p>&copy; Copyright 2023-24 | All Rights Reserved | Smart Move Financiers | Grow with us</p>
                    <p>Follow us <LinkedIn /> <Facebook /> <Instagram /> <YouTube /> </p>
                    <p>Email : <a href="mailto:mail@smfinanciers.com">mail@smfinanciers.com</a></p>
                </div>
            </div>
        </Wrapper>
    )
}