import { styled } from "styled-components";

const Wrapper = styled.div`

    margin : 50px auto;
    h1{
        width : 80%;
        margin : 0 auto 50px auto;

    }
    .inner{
        display : flex;
        justify-content : center;
        gap : 30px;
        flex-wrap : wrap;
        .testimonial{
            border-radius : 300px 300px 30px 30px;    
            text-align : center;
            background : #fff;
            padding : 30px;
            box-shadow : 2px 2px 5px #ccc;
            img{
                border-radius : 300px;
                width : 280x;
                max-width : 280px;
                border : solid 10px #f2f2f2;
                transform : scale(0.8);
            }
            display : flex;
            flex-direction : column;
            align-items : start;
            gap : 20px;
            justify-content : start;
            align-items : center;
            width : 290px;
            text-align : justify;
        }
    }
    
`

export default Wrapper