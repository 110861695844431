import { styled } from "styled-components";

const Wrapper = styled.div`
    text-align : center;
    background : #fff;
    .inner{

        padding : 50px;
        input[type=range]{
            width : 100%;
            max-width : 600px;
            padding : 10px;
            margin-top : 10px;
            transform : scale(1.5);
            @media only screen and (max-device-width: 480px) {
                width : 60%;
            }
        }
        datalist {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items : center;
            writing-mode: vertical-lr;
            width : 100%;
            max-width: 885px;
            transform: translateX(37%);
            @media only screen and (max-device-width: 480px) {
                width : 60%;
            }
        }
        div{
            padding : 30px 0;
            font-weight : 700;  
        }
    }
`

export default Wrapper