import { styled } from "styled-components";
import bg1 from './bg1.png'
import bg2 from './bg2.jpg'
import bg3 from './bg3.jpg'
import bg4 from './bg4.jpg'


const Wrapper = styled.div`
    background-size : cover !important;
    padding :100px 0;
    animation : fade 16s ease-out infinite;

    @keyframes fade{
        0%{
            background : #2251cc url(${bg1}) no-repeat center;
        }
        25%{
            background : #2251cc url(${bg1}) no-repeat center;
        }
        26%{
            background : #2251cc url(${bg2}) no-repeat center;
        }
        50%{
            background : #2251cc url(${bg2}) no-repeat center;
        }
        51%{
            background : #2251cc url(${bg3}) no-repeat center;
        }
        75%{
            background : #2251cc url(${bg3}) no-repeat center;
        }
        76%{
            background : #2251cc url(${bg4}) no-repeat center;
        }
        99%{
            background : #2251cc url(${bg4}) no-repeat center;
        }
        100%{
            background : #2251cc url(${bg1}) no-repeat center;
        }
    }

    h1{
        color : #fff;
        font-size : 50px;
    }
    p{
        color : #fff;
        margin-top : 10px;
        font-size : 30px;
    }
    input{
        background : #292;
        color : #fff;
        font-weight : bold;
        margin-top : 80px;
        padding : 20px 40px;
        border : none;
        border-radius : 5px;
        border-bottom : solid 3px #070;
    }
`

export default Wrapper