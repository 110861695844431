import Wrapper from "./style"

export const Contact = ({subject}) => {

    
    return (
        <Wrapper>
            <div className="inner">
                <h1>We are glad to hear</h1>
                <div className="form">
                    <div className="left">
                        <input type="text" placeholder="Name *" />
                        <input type="email" placeholder="Email Address *" />
                        <input type="text" placeholder="Contact" />
                        {
                            subject
                            ? null
                            : <select name="" id="">
                                <option>General Query</option>
                                <option>Become a Partner</option>
                                <option>Career Opportunity</option>
                            </select>
                        }
                    </div>
                    <textarea className="right" placeholder="Your thoughts ... (32000 Max Characters)"></textarea>
                </div>
                <input type="submit" value="Submit" />
            </div>
        </Wrapper>
    )
}