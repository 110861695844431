import { Testimonials } from "../testimonials"
import Wrapper from "./style"

export const About = () => {
    return (
        <Wrapper>
            <div className="inner">

                <h1>What we are</h1>
                <p>
                Smart Move Financiers Pvt. Ltd. is incorporated on 15th March 2018. The company is involved in financial and business advisory services.<br /><br />
                We are associated with almost all the Bankers and Financial Institutions directly and indirectly. We have direct DSA codes in Govt. Banks viz SBI, PNB, PSB, and BOB and with Pvt. Banks viz Standard Chartered Capital, ICICI Bank, Kotak, IDBI and PNBHFL. Experienced in all kinds of Project Finance, Retail Finance, Subsidies and Business advisory, providing services since 2011.
                </p>

                <h1>Our Leadership</h1>
                <div className="leader">
                    <h2>Jugal Kishore Garg – Director (Retd. Govt. Servant)</h2>
                    <p>Mr. Garg having a rich experience of more than 35 years working in the field of Accounts and Finance. He last retired as AAO from Rajasthan Roadways (State Govt. Enterprise) served various roles in accounts and finance department during the stint. He is associated with the company as a director.</p>
                </div>
                <div className="leader">
                    <h2>Dheeraj Agarwal – Consultant (Chartered Accountant, Company Secretary)</h2>
                    <p>Mr. Agarwal completed out CA in May’2011 and started his own practice in same year. Having core expertise in area of Financial Services for more than 12 years. Associated with the company as a key consultant for Project finance and NPA management services.</p>
                </div>
                <div className="leader">
                    <h2>Ashish Garg - Consultant (Chartered Accountant, Company Secretary)</h2>
                    <p>Mr. Garg completed out CA in May’2012 and having rich experience of 10 years working in Assets vertical in Banks and NBFC’s (SCB, RBL and SMFG). Now he is a practicing CA since 2022 and associated with the company as key consultant for Subsidies, Retail financing and Insurance Business.</p>
                </div>
            </div>
            <Testimonials heading="What people talk about us" />
        </Wrapper>
    )
}