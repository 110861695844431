import { styled } from "styled-components";

const Wrapper = styled.div`
    background : #fff;
    position : fixed;
    top : 0;
    width : 100vw;
    border-bottom : solid thin #ccc;

    @media only screen and (max-device-width: 480px) {
        .inner{
            width : 100%;
            margin : 0;
            padding : 0 !important;
            flex-direction : column;
            li{
                flex : 1;
            }
            a{
                padding : 10px !important;
            }
        }
    }

    .inner{
        display : flex;
        justify-content : space-between;
        padding : 10px 0;
        align-items : center;

        img{
            height : 100px;
        }

        a{
            text-decoration : none;
        }
        
        ul{
            display : flex;
            list-style : none;
            flex-wrap : wrap;
            a{
                display : inline-block;
                padding : 10px 20px;
                color : #0084cc;
                font-weight : 600;
                text-decoration : none;
                border-bottom : solid 3px #fff;
                &:hover{
                    border-bottom : solid 3px #292;
                }
            }
        }

    }
`

export default Wrapper