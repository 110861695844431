import { styled } from "styled-components";

const Wrapper = styled.div`
    padding : 50px 0;
    @media only screen and (max-device-width: 480px) {
        .form{
            flex-direction : column;
        }
    }
    .form{
        display : flex;
        margin-top : 30px;
        gap : 20px;
        .left{
            flex : 1;
            &>*{
                width : 100%;
                margin : 10px 0;
                border : none;
                font-size : 20px;
                padding : 15px;
                box-sizing : border-box;
                border-radius : 10px;
            }
        }
        .right{
            flex : 1;
            margin : 10px 0;
            border : none;
            font-size :20px;
            padding : 15px;
            border-radius : 10px;
        }
    }
    input[type=submit]{
        background : #292;
        color : #fff;
        font-weight : bold;
        margin-top : 80px;
        padding : 20px 40px;
        border : none;
        border-radius : 5px;
        border-bottom : solid 3px #070;
    }
`

export default Wrapper