import Wrapper from "./style"

export const Testimonials = ({heading}) => {



    return (
        <Wrapper>

            <h1>{heading || "Testimonials"}</h1>
            <div className="inner">
                <div className="testimonial">
                    <img src="https://scontent.fjai1-2.fna.fbcdn.net/v/t39.30808-6/334766385_1433012677468050_6793171464892014248_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=kQjYbfEn1cUAX_0ceat&_nc_ht=scontent.fjai1-2.fna&oh=00_AfB6Rrby8d44k2JxlJ_o6jZGnhtircQApR00jSy1n5RFjA&oe=65F681E4" alt="" />
                    <h2>Ashish Garg</h2>
                    <p>
                        Our value, motto & mission remains to instil the right financial knowledge among our clients and bestow the finest services to individuals seeking credit aid. Leveraging upon new-age technologies, we endeavour to create a financial environment where we can serve a large segment of the population. With the mission to ‘help you borrow right,’.
                    </p>
                </div>
                <div className="testimonial">
                    <img src="https://scontent.fjai1-1.fna.fbcdn.net/v/t1.6435-9/36559814_2394489100561966_7886581406053171200_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=IbjAi9GgLu4AX9xRZWt&_nc_ht=scontent.fjai1-1.fna&oh=00_AfDPN1ZdgovWxSAwVsrshJnD74vd4_pDnFMfPnxfHodJOQ&oe=66190A54" alt="" />
                    <h2>Dheeraj Agarwal</h2>
                    <p>
                    Leveraging upon new-age technologies, we endeavour to create a financial environment where we can serve a large segment of the population. With the mission to ‘help you borrow right,’ our core aim remains to impart principled information and bridge the gap between the credit-seeking individuals and authentic lenders.
                    </p>
                </div>
                <div className="testimonial">
                    <img src="https://scontent.fjai1-1.fna.fbcdn.net/v/t1.6435-9/123017189_3571318672921775_1131987973571667518_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=J5erDqJmDVIAX897lNl&_nc_ht=scontent.fjai1-1.fna&oh=00_AfDKizvtrTw51tcY7dO-CHO65B6HlJRS7UvPVFbVDN1Alw&oe=66191B94" alt="" />
                    <h2>Manish Gupta</h2>
                    <p>
                    With a PAN India presence across 2000+ cities, Ruloans is one of the leading credit lending establishments founded by professionals and financial experts who with an experience of 25+ years have created a rich profile of satisfying the needs of more than 10 lakhs+ clients.</p>
                </div>
                <div className="testimonial">
                    <img src="https://scontent.fjai1-3.fna.fbcdn.net/v/t1.6435-9/43628286_2382541601828962_2052360091012694016_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=a-hqLw3xU4UAX9bSvpv&_nc_ht=scontent.fjai1-3.fna&oh=00_AfCI3nWlgd-oPuWDkOijSZYOTu3IOsYzR--nhvYTUVw_jg&oe=66192DAD" alt="" />
                    <h2>Shubham Gupta</h2>
                    <p>
                    The monthly part-payment that you make to repay your Home Loan is known as an Equated Monthly Instalment (EMI). This monthly payment includes repayment of the principal amount of your loan divided over the period of the loan, with the agreed interest amount on the outstanding amount of your home loan.</p>
                </div>
            </div>

        </Wrapper>
    )
}