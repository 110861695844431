import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Header } from './component/header';
import { Cover } from './component/cover';
import { Services } from './component/services';
import { Testimonials } from './component/testimonials';
import { Footer } from './component/footer';
import { Blogs } from './component/blogs';
import { Contact } from './component/contact';
import { About } from './component/about';
import { Calculator } from './component/calc';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route 
          path='/*'
          exact
          element={
            <>
              <Cover />
              <Services />
              <Testimonials />
              <Calculator />
              {/* <Blogs /> */}
              
            </>
          }
        />

        <Route 
          path='/about'
          exact
          element={<About />}
        />

        <Route 
          path='/services'
          exact
          element={
            <>
              <Services />
            </>
          }
        />

        <Route 
          path='/blogs'
          exact
          element={
            <>
              <Blogs />
            </>
          }
        />

        <Route 
          path='/contact'
          exact
          element={
            <></>
          }
        />
      </Routes>
      <Contact />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
