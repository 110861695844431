import Wrapper from "./style"
import tree from "./sm-logo.png"
import { Link } from "react-router-dom"

export const Header = () => {
    return (
        <Wrapper>
            <div className="inner">
                <Link to="/"><img src={tree} /></Link>
                <nav>
                    <ul>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/blogs">Blog</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        {/* <li><a href="">+91 9782312993</a></li> */}
                    </ul>
                </nav>
            </div>
        </Wrapper>
    )
}