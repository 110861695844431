import { styled } from "styled-components";

const Wrapper = styled.div`
    padding : 50px 0;
    background : #fff;
    h1{
        padding-bottom : 50px;
    }
    .cards{
        display : flex;
        flex-wrap : wrap;
        justify-content : center;
        .card{
            width : 33%;
            min-width : 300px;
            transition : 0.4s;
            text-align : center;
            padding : 80px 5px;
            box-sizing : border-box;
            border : solid thin #fff;
            cursor : pointer;
            img{
                width : 100px;
                transition : 0.4s;
            }
            h2{
                font-size : 20px;
                font-weight : 400;
                color : #0084cc;
                margin-top : 30px;
            }
            &:hover{
                background : #f2f2f2;
                img{
                    transform : translateY(-10%)
                }
            }
        }
    }

    .popupWrapper{
        position : fixed;
        width : 100vw;
        height : 100vh;
        top : 0;
        left : 0;
        background : rgba(0,0,0,0.3);
        display : flex;
        align-items : center;
        .servicePopup{
            text-align : center;
            border-radius : 10px;
            width : 100%;
            height : 100%;
            background : #f2f2f2;
            display : flex;
            align-items : center;
            justify-content : center;
            flex-direction : column;
            padding : 30px;
            margin : auto;
            >div{
                padding : 0;

            }
            p{
                max-width : 90%;
            }

            input[type=button]{
                background : #292;
                color : #fff;
                font-weight : bold;
                margin-top : 80px;
                padding : 20px 40px;
                border : none;
                border-radius : 5px;
                border-bottom : solid 3px #070;
            }

            .inner{
                width : 100%;
                .form{
                    flex-direction : column;
                }
                h1{
                    display : none;
                }
            }
        }
    }
`

export default Wrapper